import React from "react"
import styled from "styled-components"
import BaseImg from "./baseImg"
import { Container, BaseButton } from "../components/styledComponents"

const Section = styled.div`
  position: relative;
  margin: 10rem 0 12rem;

  @media screen and (max-width: 767px) {
    margin: 10rem 0 2rem;
  }
`
const ValuesWrapper = styled.div`
  width: 50%;
  position: relative;
  padding: 1.75rem 1rem 1.75rem 0;

  @media screen and (max-width: 1299px) {
    width: 55%;
  }
  @media screen and (max-width: 991px) {
    width: 65%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`

const PartnersWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 2.0625rem;
`
const SinglePartner = styled.div`
  flex-basis: 30%;
  height: 52px;
  div {
    height: 100%;
  }
  img {
    object-fit: contain !important;
    margin-bottom: 0;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 44rem;
  width: 44rem;
  /* height: 62.75rem;
  width: 62.75rem; */

  /* @media screen and (max-width: 1920px) {
    right: -12.5rem;
  } */
  @media screen and (max-width: 1600px) {
    right: -10rem;
  }
  /* @media screen and (max-width: 1299px) {
    height: 56rem;
    width: 56rem;
  }

  @media screen and (max-width: 1199px) {
    height: 50rem;
    width: 50rem;
  } */

  @media screen and (max-width: 1199px) {
    right: -15rem;
  }

  @media screen and (max-width: 991px) {
    height: 40rem;
    width: 40rem;
    right: -20rem;
  }
  @media screen and (max-width: 767px) {
    /* display: none; */
    position: static;
    transform: translateY(0);
    width: 100%;
    height: auto;
  }

  > div {
    height: 100%;
  }

  img {
    max-height: 100%;
    object-position: left center !important;
    margin-bottom: 0;
  }
`

const Heading = styled.h2`
  font-size: 2.5rem;
  line-height: 3.0625rem;
  margin-bottom: 0.5rem;
`
const Description = styled.p`
  font-size: 1.125rem;
  line-height: 1.8125rem;
  margin-bottom: 1.25rem;
`
const ButtonWrapper = styled.div`
  max-width: 23.5rem;
  max-height: 2.875rem;

  > a {
    width: 100%;
    padding: 0;
    line-height: 46px;
  }
`

const BorderedBox = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 66%;
  height: calc(100% + 74px);
  border: 1px solid ${(props) => props.theme.secondary};
  border-left: none;
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 24px;
    border-left: 1px solid ${(props) => props.theme.secondary};
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
`

const CaseStudySection = ({
  image,
  title,
  text,
  partners,
  buttonText,
  buttonUrl,
}) => {
  return (
    <Section>
      <Container>
        <ValuesWrapper>
          <Heading>{title}</Heading>
          <Description>{text}</Description>
          {partners ? (
            <PartnersWrapper>
              {partners.map((partner) => (
                <SinglePartner
                  key={partner.image.localFile.childImageSharp.fluid.base64}
                >
                  <BaseImg localFile={partner.image.localFile} />
                </SinglePartner>
              ))}
            </PartnersWrapper>
          ) : null}
          <ButtonWrapper>
            <BaseButton to={buttonUrl}>{buttonText}</BaseButton>
          </ButtonWrapper>
          <BorderedBox />
        </ValuesWrapper>
      </Container>
      <ImageWrapper>
        <BaseImg localFile={image.localFile} />
      </ImageWrapper>
    </Section>
  )
}

export default CaseStudySection
