import React from "react"
import styled from "styled-components"
import BaseImg from "./baseImg"

const Section = styled.div`
  margin-top: 7.5rem;
  position: relative;
`

const ImageWrapper = styled.div`
  max-width: 988px;
  margin: 0 auto;
`

const TextWrapper = styled.div`
  max-width: 580px;
  margin: -90px auto 0;
  padding: 34px 35px;
  position: relative;
  z-index: 1;

  box-shadow: 0px 3px 6px #00000029;
  background-color: #fff;

  @media screen and (max-width: 900px) {
    margin: -30px auto 0;
  }
  @media screen and (max-width: 787px) {
    margin: 0 auto 0;
  }
`

const Title = styled.h3`
  text-align: center;
`

const Description = styled.p`
  text-align: center;
`

const AboutSection = ({ image, title, text }) => {
  return (
    <Section>
      <ImageWrapper>
        <BaseImg localFile={image} />
      </ImageWrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <Description>{text}</Description>
      </TextWrapper>
    </Section>
  )
}

export default AboutSection
